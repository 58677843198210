@font-face {
  font-family: "Open Sans";
  src: url("/public/font/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("/public/font/OpenSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("/public/font/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: "Open Sans";
  src: url("/public/font/OpenSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@import '~ngx-toastr/toastr';

$results-red: #C0392B;

* {
  font-family: "Open Sans", sans-serif;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 406px;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #f5f5f5;
}

.separator {
  border-left: 0.5px solid #666;
  border-right: 0.5px solid #666;
  margin: 0 9px;
}

.btn-horsetelex-results {
  background-color: $results-red;
  color: white;
  text-transform: uppercase;

  &:hover {
    background-color: darken($results-red, 6%);
    color: white;
    cursor: pointer;
  }
}

.advanced-search {
  text-decoration: underline;
  color: black;
  font-size: 15px;

  &:hover {
    cursor: pointer;
    color: $results-red;
  }
}

$container-width: 1020px;
$pedigree-green: #297825;

.container {
  width: $container-width;
}

.ipv-table {
  font-size: 14px;

  th, td {
    padding: 5px;
  }

  th {
    text-transform: uppercase;
  }

  td {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.bg-results {
  background-color: $results-red;
}

a, .nav-link {
  color: $results-red;
  text-decoration: none;

  &:hover {
    color: darken($results-red, 15%);
  }
}

.btn-xs {
  padding: 0.25rem 0.3rem;
  font-size: 0.8rem;
  line-height: 1;
  border-radius: 0.2rem;
}

.cursor-pointer {
  cursor: pointer;
}

.required:after {
  content: " *";
  color: #A40C04;
}

.form-group {
  margin-bottom: 1rem;
}

.btn-disabled {
  opacity: 65%;
}

.text-results {
  color: #c0392b;
}

.btn-results {
  color: white;
  background-color: #c0392b;
  border-color: #c0392b;

  &:hover {
    background-color: white;
    color: #c0392b;

  }
}
